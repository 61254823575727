.education__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.education__container > div:first-child {
  grid-column: 1 / 3;
}

.education__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.education__container a {
  color: var(--color-light);
}

.education__container a svg {
  margin-left: 0.2rem;
  margin-bottom: -0.1rem;
}

.education__container > div:hover {
  background: var(--color-primary-variant);
  cursor: default;
}

.education__container > div:hover h4,
.education__container > div:hover small,
.education__container > div:hover a,
.education__container > div:hover svg,
.education__container > div:hover a svg {
  color: var(--color-white);
}

.education__container > div:hover a:hover {
  text-decoration-line: underline;
}

.education__container > div h3 {
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 2rem;
  color: var(--color-white);
}

.education__container > div h4 {
  color: var(--color-light);
}

.education__content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}

.education__details {
  display: flex;
  gap: 1rem;
}

.education__details-icon {
  color: var(--color-primary);
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  margin-top: 6px;
}

.education__details-text {
  margin-left: 2rem;
}

small {
  display: block;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  .education__container {
    grid-template-columns: 1fr;
  }

  .education__container > div:first-child {
    grid-column: 1;
  }

  .education__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .education__content {
    padding: 1rem;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .education__container {
    gap: 1rem;
  }

  .education__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}

.projects__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.projects__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.projects__item:hover {
  background: var(--color-primary-variant);
  cursor: default;
}

.projects__item:hover h3,
.projects__item:hover svg {
  color: var(--color-white);
}

.projects__item-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  width: auto;
}

.projects__item-image img {
  display: block;
  margin: auto;
  width: 50%;
}

.projects__item h3 {
  color: var(--color-light);
  font-size: large;
}

.projects__item-icon {
  position: absolute;
}

.projects__item-icon svg {
  display: block;
  transform: rotate(45deg);
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-top: -8rem;
  margin-left: -1rem;
  height: auto;
}

.projects__item-modal {
  background: rgb(48, 46, 46);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.projects__item-modal h3 {
  color: var(--color-white);
  font-size: large;
}

.projects__item-modal-info {
  margin: 1rem 0 1rem;
}

.projects__item-modal-cta {
  display: flex;
  gap: 1rem;
}

.projects__item-modal-cta a {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  .projects__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }

  .projects__item-modal {
    margin: 0 5% 0 5%;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .projects__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

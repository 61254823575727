.experience__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div:hover small,
.experience__container > div:hover svg,
.experience__container > div:hover h4 {
  color: var(--color-white);
}

.experience__container > div h3 {
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 2rem;
  color: var(--color-white);
}

.experience__container > div h4 {
  color: var(--color-light);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/* =============== PEOGRESS BAR =============== */
.progress {
  background-color: var(--color-white);
  border-radius: 10px;
  position: relative;
  margin: 0.5rem 0;
  height: 1rem;
  width: 10rem;
}

.progress-done {
  background: linear-gradient(
    to left,
    var(--color-primary-variant),
    var(--color-primary)
  );
  border-radius: 10px;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  height: 100%;
  width: 0;
  transition: var(--transition);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }

  .progress {
    width: 6rem;
  }
}

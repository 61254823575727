header {
  height: 55rem;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ============= CTA ============= */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ============= HEADER SOCIALS ============= */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  position: absolute;
  left: 0;
  bottom: 2rem;
}

.header__socials a {
  font-size: 1.1rem;
}

.header__socials::after {
  content: "";
  width: 2px;
  height: 2rem;
  background: var(--color-primary);
}

/* ============= ME ============= */
.me {
  image-rendering: optimizeQuality;
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 25rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 5rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ============= SCROLL DOWN ============= */
.header__scroll-down {
  position: absolute;
  right: -2.3rem;
  bottom: 4.5rem;
  transform: rotate(90deg);
  font-weight: 400;
  font-size: 1.2rem;
}

.header__scroll-down svg {
  position: absolute;
  transform: rotate(270deg);
  bottom: 0.3rem;
  font-size: 1.4rem;
}

.header__scroll-down:hover + svg {
  color: var(--color-white);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .header__socials,
  .header__scroll-down,
  .header__scroll-down-icon {
    display: none;
  }
}
